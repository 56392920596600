import { CompanySetup } from "../_types_/CompanySetupType";

export const PAYMENT_TERM_5_DAYS_NET = "0020";
export const PAYMENT_TERM_14_DAYS_NET = "0027";
export const PAYMENT_TERM_30_DAYS_NET = "0002";
export const PAYMENT_TERM_45_DAYS_NET = "0008";
export const PAYMENT_TERM_60_DAYS_NET = "0003";
export const PAYMENT_TERM_15_DAYS_NET = "0026";
export const PAYMENT_TERM_10_DAYS_NET = "0005";
export const PAYMENT_TERM_17_DAYS_NET = "0014";
export const PAYMENT_TERM_CASH_BEFORE_DELIVERY = "0119";
export const PAYMENT_TERM_PREPAYMENT = "0137";
export const PAYMENT_TERM_10_DAYS_MINUS_4 = "0013";
export const PAYMENT_TERM_10_DAYS_MINUS_4_5 = "0006";
export const PAYMENT_TERM_14_DAYS_MINUS_2 = "0058";
export const PAYMENT_TERM_30_DAYS_NET_10_DAYS_MINUS_3 = "0012";
export const PAYMENT_TERM_30_DATS_NET_10_DAYS_MINUS_4 = "0004";
export const PAYMENT_TERM_END_OF_MONTH_PLUS_30_DAYS = "0010";
export const PAYMENT_TERM_END_OF_MONTH_PLUS_45_DAYS = "0036";
export const PAYMENT_TERM_END_OF_MONTH_PLUS_60_DAYS = "0007";
export const PAYMENT_TERM_30_DAYS_NET_PLUS_END_OF_MONTH = "IT01";

export const PLEASE_SELECT_BRAND_CODE = "0";
export const PLEASE_SELECT_PRODUCT_LINE_CODE = "0";
export const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CALLING_CODE_VALIDATION_REGEX = /^(\+\d{1,3})$/;
export const ONLY_NUMBERS_VALIDATION_REGEX = /^[0-9]*$/;

export const paymentTermHasDirectDebit = (fileCardCompanyCode: number, fileCardPaymentTermCode: string | null) => {
  const company = ALL_COMPANIES.find((c) => c.code === fileCardCompanyCode);

  const paymentTerms = company?.paymentTerms;

  if (paymentTerms?.length === 0) {
    return true; //Should not disable billing when no payment terms exist
  }

  if (fileCardPaymentTermCode === null) {
    return false;
  }

  if (paymentTerms !== undefined) {
    return paymentTerms.find((pt) => pt.code === fileCardPaymentTermCode)?.directDebit;
  } else {
    return false;
  }
};

export const ALL_COMPANIES: CompanySetup[] = [
  {
    code: 483,
    name: "BESTSELLER Wholesale A/S",
    language: "da",
    country: "DNK",
    callingCode: "+45",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Forudbetaling", language: "da" },
          { value: "Cash before delivery", language: "en" },
        ],
        description: [
          {
            value: "Faktura skal være registreret betalt, inden varerne kan frigives fra lageret til levering",
            language: "da",
          },
          {
            value:
              "The invoice must be registered and paid before the goods can be released from the warehouse for delivery",
            language: "en",
          },
        ],
      },
      {
        code: PAYMENT_TERM_10_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "10 dage netto ved manuel betaling", language: "da" },
          { value: "10 days net by manual payment", language: "en" },
        ],
        description: [
          {
            value:
              "Forfalden saldo betales hver den 5. 15. og 25. efter kontoudtog. HUSK at notere betalingsreferencenummer",
            language: "da",
          },
          {
            value:
              "Balance due is paid each on the 5th, 15th and 25th after bank statements. REMEMBER to note down the payment reference number",
            language: "en",
          },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [
          { value: "30 dage netto via Nets Leverandørservice", language: "da" },
          { value: "30 days net via Nets Leverandørservice", language: "en" },
        ],
        description: [
          {
            value: "Forfalden saldo trækkes via Nets Leverandørservice hver den 5., 15. og 25.",
            language: "da",
          },
          {
            value: "Balance due is deducted via Nets Leverandørservice every 5th, 15th and 25th.",
            language: "en",
          },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: false,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: false,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: true,
    regNumberMinDigits: 4,
    regNumberMaxDigits: 4,
    accountNumber: true,
    BIC: false,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["EN"],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 484,
    name: "VILA Wholesale A/S",
    language: "da",
    country: "DNK",
    callingCode: "+45",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Forudbetaling", language: "da" },
          { value: "Cash before delivery", language: "en" },
        ],
        description: [
          {
            value: "Faktura skal være registreret betalt, inden varerne kan frigives fra lageret til levering",
            language: "da",
          },
          {
            value:
              "The invoice must be registered and paid before the goods can be released from the warehouse for delivery",
            language: "en",
          },
        ],
      },
      {
        code: PAYMENT_TERM_10_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "10 dage netto ved manuel betaling", language: "da" }],
        description: [
          {
            value:
              "Forfalden saldo betales hver den 5. 15. og 25. efter kontoudtog. HUSK at notere betalingsreferencenummer",
            language: "da",
          },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "30 dage netto via Nets Leverandørservice", language: "da" }],
        description: [
          {
            value: "Forfalden saldo trækkes via Nets Leverandørservice hver den 5., 15. og 25.",
            language: "da",
          },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: false,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: false,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: true,
    regNumberMinDigits: 4,
    regNumberMaxDigits: 4,
    accountNumber: true,
    BIC: false,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 251,
    name: "BESTSELLER Wholesale Benelux B.V.",
    language: "nl",
    country: "NLD",
    callingCode: "+31",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "", language: "nl" }],
        description: [{ value: "", language: "nl" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: false,
    guaranteeAmountReadOnlyForCustomer: false,
    guaranteeType: false,
    kvkNumber: true,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: true,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: [
      { code: 0, name: "SEPA 30 days" },
      { code: 1, name: "SEPA 2x 14 days" },
    ],
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 263,
    name: "VILA Benelux B.V.",
    language: "nl",
    country: "NLD",
    callingCode: "+31",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "", language: "nl" }],
        description: [{ value: "", language: "nl" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: false,
    guaranteeAmountReadOnlyForCustomer: false,
    guaranteeType: false,
    kvkNumber: true,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: true,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: [
      { code: 0, name: "SEPA 30 days" },
      { code: 1, name: "SEPA 2x 14 days" },
    ],
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 242,
    name: "BESTSELLER Sverige AB",
    language: "se",
    country: "SWE",
    callingCode: "+46",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "", language: "se" }],
        description: [{ value: "", language: "se" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: false,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 278,
    name: "VILA Sweden AB",
    language: "se",
    country: "SWE",
    callingCode: "+46",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "", language: "se" }],
        description: [{ value: "", language: "se" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: false,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 206,
    name: "BESTSELLER AS",
    language: "no",
    country: "NOR",
    callingCode: "+47",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_15_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "", language: "no" }],
        description: [{ value: "", language: "no" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: true,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: true,
    comment: true,
    logisticContact: false,
    noSingleReturn: false,
    hideAddShipToButton: true,
    createAdminUser: true,
  },
  {
    code: 273,
    name: "VILA Norge AS",
    language: "no",
    country: "NOR",
    callingCode: "+47",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_15_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "", language: "no" }],
        description: [{ value: "", language: "no" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: true,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: true,
    comment: true,
    logisticContact: false,
    noSingleReturn: false,
    hideAddShipToButton: true,
    createAdminUser: true,
  },
  {
    code: 253,
    name: "BESTSELLER Wholesale France SaS",
    language: "fr",
    country: "FRA",
    callingCode: "+33",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [],
    dataProtectionCheckmark: false,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: false,
    guarantee: false,
    guaranteeAmountReadOnlyForCustomer: false,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: true,
    signerDropDown: false,
    billing: true,
    sirenNumber: true,
    contactPersonFunction: true,
    contractTypes: [
      { code: 0, name: "Pay by Link" },
      { code: 1, name: "Direct Debit" },
      { code: 2, name: "Inter Sport" },
    ],
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: false,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
    directDebitBICOptional: true,
    directDebitIBANOptional: true,
    directDebitLegalNameOptional: true,
    directDebitStoreNameOptional: true,
  },
  {
    code: 270,
    name: "VILA France SaS",
    language: "fr",
    country: "FRA",
    callingCode: "+33",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [],
    dataProtectionCheckmark: false,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: false,
    guarantee: false,
    guaranteeAmountReadOnlyForCustomer: false,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: true,
    signerDropDown: false,
    billing: true,
    sirenNumber: true,
    contactPersonFunction: true,
    contractTypes: [
      { code: 0, name: "Pay by Link" },
      { code: 1, name: "Direct Debit" },
      { code: 2, name: "Inter Sport" },
    ],
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: false,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
    directDebitBICOptional: true,
    directDebitIBANOptional: true,
    directDebitLegalNameOptional: true,
    directDebitStoreNameOptional: true,
  },
  {
    code: 245,
    name: "BESTSELLER Textilhandels GmbH",
    language: "de",
    country: "DEU",
    callingCode: "+49",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Vorkasse rein netto", language: "de" }],
        description: [
          {
            value: "*Zahlung muss innerhalb von 3 Werktagen nach Rechnungserstellung erfolgen",
            language: "de",
          },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "SEPA Firmenlastschrift 30 Tage netto", language: "de" }],
        description: [{ value: "", language: "de" }],
      },
      {
        code: PAYMENT_TERM_10_DAYS_MINUS_4,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [
          {
            value: "SEPA Firmenlastschrift Verfahren 10 Tage - 4%",
            language: "de",
          },
        ],
        description: [{ value: "", language: "de" }],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET_10_DAYS_MINUS_3,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Überweisung 30 Tage netto / 10 Tage - 3%", language: "de" }],
        description: [{ value: "", language: "de" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: false,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: true,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: [{ code: 0 }, { code: 2 }],
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: false,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 246,
    name: "VILA GmbH",
    language: "de",
    country: "DEU",
    callingCode: "+49",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Vorkasse rein netto", language: "de" }],
        description: [
          {
            value: "*Zahlung muss innerhalb von 3 Werktagen nach Rechnungserstellung erfolgen",
            language: "de",
          },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "SEPA Firmenlastschrift 30 Tage netto", language: "de" }],
        description: [{ value: "", language: "de" }],
      },
      {
        code: PAYMENT_TERM_10_DAYS_MINUS_4,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [
          {
            value: "SEPA Firmenlastschrift Verfahren 10 Tage - 4%",
            language: "de",
          },
        ],
        description: [{ value: "", language: "de" }],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET_10_DAYS_MINUS_3,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Überweisung 30 Tage netto / 10 Tage - 3%", language: "de" }],
        description: [{ value: "", language: "de" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: false,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: true,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: [{ code: 0 }, { code: 2 }],
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: false,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 250,
    name: "BESTSELLER Wholesale Belgium BV",
    language: "nl",
    country: "",
    callingCode: "+32",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "", language: "nl" }],
        description: [{ value: "", language: "nl" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: false,
    guarantee: false,
    guaranteeAmountReadOnlyForCustomer: false,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: true,
    signerDropDown: true,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: false,
    storePhoneNumberRequired: false,
    alernativeLanguages: ["FR"],
    bankName: false,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
  },
  {
    code: 262,
    name: "VILA Belgium BV",
    language: "nl",
    country: "",
    callingCode: "+32",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "", language: "nl" }],
        description: [{ value: "", language: "nl" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: false,
    guarantee: false,
    guaranteeAmountReadOnlyForCustomer: false,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: true,
    signerDropDown: true,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: false,
    storePhoneNumberRequired: false,
    alernativeLanguages: ["FR"],
    bankName: false,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
  },
  {
    code: 256,
    name: "BESTSELLER Wholesale UK Ltd.",
    language: "en",
    country: "GBR",
    callingCode: "+44",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Immediate", language: "en" }],
        description: [{ value: "", language: "en" }],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "30 day net", language: "en" }],
        description: [{ value: "", language: "en" }],
      },
      {
        code: PAYMENT_TERM_60_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "60 day net", language: "en" }],
        description: [{ value: "", language: "en" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: true,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: false,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: true,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 267,
    name: "VILA Clothes Ltd.",
    language: "en",
    country: "GBR",
    callingCode: "+44",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Immediate", language: "en" }],
        description: [{ value: "", language: "en" }],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "30 day net", language: "en" }],
        description: [{ value: "", language: "en" }],
      },
      {
        code: PAYMENT_TERM_60_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "60 day net", language: "en" }],
        description: [{ value: "", language: "en" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: true,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    accountNumber: false,
    BIC: false,
    signerDropDown: false,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: true,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 212,
    name: "BESTSELLER Handels GmbH",
    language: "de_at",
    country: "", //AUT
    callingCode: "+43",
    smsCodeCellPhoneNumber: false,
    paymentTerms: [
      {
        code: PAYMENT_TERM_14_DAYS_MINUS_2,
        directDebit: true,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        name: [
          {
            value: "SEPA Firmenlastschrift 14 Tage -2% Skonto  (1/12 Sicherheitsleistung)",
            language: "de_at",
          },
          {
            value: "Direct Debit 14 days -2% discount (1/12 security)",
            language: "EN_AT",
          },
        ],
        description: [
          { value: "", language: "de_at" },
          { value: "", language: "EN_AT" },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          {
            value: "30 Tage netto (2/12 Sicherheitsleistung)",
            language: "de_at",
          },
          { value: "30 days net (2/12 security)", language: "EN_AT" },
        ],
        description: [
          { value: "", language: "de_at" },
          { value: "", language: "EN_AT" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: "AUT",
    regNumber: true,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: true,
    BIC: false,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["EN_AT"],
    bankName: true,
    deliveryDate: false,
    comment: true,
    logisticContact: true,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 265,
    name: "VILA Clothes Handels GmbH",
    language: "de_at",
    country: "", //AUT
    callingCode: "+43",
    smsCodeCellPhoneNumber: false,
    paymentTerms: [
      {
        code: PAYMENT_TERM_14_DAYS_MINUS_2,
        directDebit: true,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        name: [
          {
            value: "SEPA Firmenlastschrift 14 Tage -2% Skonto  (1/12 Sicherheitsleistung)",
            language: "de_at",
          },
          {
            value: "Direct Debit 14 days -2% discount (1/12 security)",
            language: "EN_AT",
          },
        ],
        description: [
          { value: "", language: "de_at" },
          { value: "", language: "EN_AT" },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          {
            value: "30 Tage netto (2/12 Sicherheitsleistung)",
            language: "de_at",
          },
          { value: "30 days net (2/12 security)", language: "EN_AT" },
        ],
        description: [
          { value: "", language: "de_at" },
          { value: "", language: "EN_AT" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: "AUT",
    regNumber: true,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: true,
    BIC: false,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["EN_AT"],
    bankName: true,
    deliveryDate: false,
    comment: true,
    logisticContact: true,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 205,
    name: "BESTSELLER (Schweiz) AG",
    language: "de_ch",
    country: "CHE",
    callingCode: "+41",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Vorkasse rein netto", language: "de_ch" },
          { value: "Prepayment net", language: "EN_CH" },
        ],
        description: [
          { value: "", language: "de_ch" },
          { value: "", language: "EN_CH" },
        ],
      },
      {
        code: PAYMENT_TERM_10_DAYS_MINUS_4_5,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [
          {
            value: "Firmenlastschrift Verfahren 10 Tage - 4,5%",
            language: "de_ch",
          },
          { value: "Corporate direct debit 10 days - 4.5%", language: "EN_CH" },
        ],
        description: [
          { value: "", language: "de_ch" },
          { value: "", language: "EN_CH" },
        ],
      },
      {
        code: PAYMENT_TERM_30_DATS_NET_10_DAYS_MINUS_4,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          {
            value: "Überweisung 30 Tage netto / 10 Tage - 4%",
            language: "de_ch",
          },
          { value: "Bank transfer 30 days / 10 days - 4%", language: "EN_CH" },
        ],
        description: [
          { value: "", language: "de_ch" },
          { value: "", language: "EN_CH" },
        ],
      },
      {
        code: PAYMENT_TERM_10_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          {
            value: "10 Tage netto",
            language: "de_ch",
          },
          { value: "10 days net", language: "EN_CH" },
        ],
        description: [
          { value: "", language: "de_ch" },
          { value: "", language: "EN_CH" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: true,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: true,
    BIC: false,
    signerDropDown: true,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: [{ code: 0 }, { code: 2 }],
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["EN_CH"],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: true,
    createAdminUser: true,
  },
  {
    code: 264,
    name: "VILA Clothes AG",
    language: "de_ch",
    country: "CHE",
    callingCode: "+41",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Vorkasse rein netto", language: "de_ch" },
          { value: "Prepayment net", language: "EN_CH" },
        ],
        description: [
          { value: "", language: "de_ch" },
          { value: "", language: "EN_CH" },
        ],
      },
      {
        code: PAYMENT_TERM_10_DAYS_MINUS_4_5,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [
          {
            value: "Firmenlastschrift Verfahren 10 Tage - 4,5%",
            language: "de_ch",
          },
          { value: "Corporate direct debit 10 days - 4.5%", language: "EN_CH" },
        ],
        description: [
          { value: "", language: "de_ch" },
          { value: "", language: "EN_CH" },
        ],
      },
      {
        code: PAYMENT_TERM_30_DATS_NET_10_DAYS_MINUS_4,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          {
            value: "Überweisung 30 Tage netto / 10 Tage - 4%",
            language: "de_ch",
          },
          { value: "Bank transfer 30 days / 10 days - 4%", language: "EN_CH" },
        ],
        description: [
          { value: "", language: "de_ch" },
          { value: "", language: "EN_CH" },
        ],
      },
      {
        code: PAYMENT_TERM_10_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          {
            value: "10 Tage netto",
            language: "de_ch",
          },
          { value: "10 days net", language: "EN_CH" },
        ],
        description: [
          { value: "", language: "de_ch" },
          { value: "", language: "EN_CH" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: true,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: true,
    BIC: false,
    signerDropDown: true,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: [{ code: 0 }, { code: 2 }],
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["EN_CH"],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: true,
    createAdminUser: true,
  },
  {
    code: 255,
    name: "BESTSELLER Wholesale Spain, S.L.U.",
    language: "es",
    country: "", //"ESP",
    callingCode: "+34",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [
          { value: "30 días fecha factura", language: "es" },
          { value: "30 dias vencimento de factura", language: "PT" },
        ],
        description: [
          { value: "", language: "es" },
          { value: "", language: "PT" },
        ],
      },
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Pago antes de la entrega", language: "es" },
          { value: "Pagamento antecipado", language: "PT" },
        ],
        description: [
          { value: "", language: "es" },
          { value: "", language: "PT" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: false,
    BIC: true,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["PT"],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 276,
    name: "VILA Spain, S.L.U.",
    language: "es",
    country: "", //"ESP",
    callingCode: "+34",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [
          { value: "30 días fecha factura", language: "es" },
          { value: "30 dias vencimento de factura", language: "PT" },
        ],
        description: [
          { value: "", language: "es" },
          { value: "", language: "PT" },
        ],
      },
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Pago antes de la entrega", language: "es" },
          { value: "Pagamento antecipado", language: "PT" },
        ],
        description: [
          { value: "", language: "es" },
          { value: "", language: "PT" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: false,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: false,
    BIC: true,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["PT"],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 302,
    name: "BESTSELLER Wholesale USA LLC",
    language: "en_us",
    country: "USA",
    callingCode: "+1",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_45_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "45 days net", language: "en_us" }],
        description: [{ value: "", language: "en_us" }],
      },
      {
        code: PAYMENT_TERM_PREPAYMENT,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Prepayment", language: "en_us" }],
        description: [{ value: "", language: "en_us" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: true,
    budget: false,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: false,
    BIC: false,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    storeCellPhoneNumberHidden: true,
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: true,
    logisticContact: false,
    noSingleReturn: false,
    taxJurisdictions: true,
    companyRegistrationNumberNotApplicable: true,
    salesTaxVendor: true,
    useEDI: false,
    hasVendorManual: false,
    deliveryTermDownloadLink: true,
    shippingCompanies: [
      { code: "1", value: "UPS" },
      { code: "2", value: "FedEx" },
      { code: "3", value: "USPS" },
      { code: "0", value: "None / Other" },
    ],
    freightChargeCheckbox: true,
    noReturnDamageCheckbox: true,
    singlePaymentTermAcceptCheckBox: true,
    copyBillToAddressButton: true,
    creditCardFeeCheckbox: true,
    hideStatementOfAccountEmail: true,
  },
  {
    code: 254,
    name: "BESTSELLER Wholesale Finland Oy:n",
    language: "en_fi",
    country: "",
    callingCode: "+358",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_17_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "17 days net", language: "en_fi" },
          { value: "17 pv netto", language: "FI" },
        ],
        description: [
          { value: "", language: "en_fi" },
          { value: "", language: "FI" },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "30 days net", language: "en_fi" },
          { value: "30 pv netto", language: "FI" },
        ],
        description: [
          { value: "", language: "en_fi" },
          { value: "", language: "FI" },
        ],
      },
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Cash before delivery", language: "en_fi" },
          { value: "Cash before delivery", language: "FI" },
        ],
        description: [
          { value: "", language: "en_fi" },
          { value: "", language: "FI" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: undefined,
    hideCompanyRegistrationNumberForCountry: "FIN",
    legalFormExclusiveCountry: "FIN",
    regNumber: false,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: false,
    BIC: false,
    signerDropDown: true,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: [{ code: 0 }, { code: 1 }, { code: 2 }, { code: 3 }],
    legalFormCompanyRegistrationNumberDisabled: true,
    legalFormDistrictCourtDisabled: true,
    legalFormCodeWithLegalFormTextEnabled: 3,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["FI"],
    bankName: false,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    ovtExclusiveCountry: "FIN",
    operatorExclusiveCountry: "FIN",
    additionalAddressInformation: true,
    createAdminUser: true,
  },
  {
    code: 269,
    name: "VILA Finland Oy:n",
    language: "en_fi",
    country: "",
    callingCode: "+358",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_17_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "17 days net", language: "en_fi" },
          { value: "17 pv netto", language: "FI" },
        ],
        description: [
          { value: "", language: "en_fi" },
          { value: "", language: "FI" },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "30 days net", language: "en_fi" },
          { value: "30 pv netto", language: "FI" },
        ],
        description: [
          { value: "", language: "en_fi" },
          { value: "", language: "FI" },
        ],
      },
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Cash before delivery", language: "en_fi" },
          { value: "Cash before delivery", language: "FI" },
        ],
        description: [
          { value: "", language: "en_fi" },
          { value: "", language: "FI" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: undefined,
    hideCompanyRegistrationNumberForCountry: "FIN",
    regNumber: false,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: false,
    BIC: false,
    signerDropDown: true,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: [{ code: 0 }, { code: 1 }, { code: 2 }, { code: 3 }],
    legalFormCompanyRegistrationNumberDisabled: true,
    legalFormDistrictCourtDisabled: true,
    legalFormExclusiveCountry: "FIN",
    legalFormCodeWithLegalFormTextEnabled: 3,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["FI"],
    bankName: false,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    ovtExclusiveCountry: "FIN",
    operatorExclusiveCountry: "FIN",
    additionalAddressInformation: true,
    createAdminUser: true,
  },
  {
    code: 208,
    name: "BESTSELLER Birleşik Tekstil Toptan ve Perakende Ticaret Limited Şirketi",
    language: "tr",
    country: "",
    callingCode: "+90",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_END_OF_MONTH_PLUS_30_DAYS,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "End of Month + 30 days", language: "EN_TR" },
          { value: "Ay+30", language: "tr" },
        ],
        description: [
          { value: "", language: "EN_TR" },
          { value: "", language: "tr" },
        ],
      },
      {
        code: PAYMENT_TERM_END_OF_MONTH_PLUS_60_DAYS,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "End of Month + 60 days", language: "EN_TR" },
          { value: "Ay+60", language: "tr" },
        ],
        description: [
          { value: "", language: "EN_TR" },
          { value: "", language: "tr" },
        ],
      },
      {
        code: PAYMENT_TERM_5_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "5 days net", language: "EN_TR" },
          { value: "Net 5 gün", language: "tr" },
        ],
        description: [
          { value: "", language: "EN_TR" },
          { value: "", language: "tr" },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "30 days net", language: "EN_TR" },
          { value: "Net 30 gün", language: "tr" },
        ],
        description: [
          { value: "", language: "EN_TR" },
          { value: "", language: "tr" },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: true,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: true,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: true,
    BIC: false,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["EN_TR"],
    bankName: true,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    createAdminUser: true,
  },
  {
    code: 252,
    name: "BESTSELLER Wholesale Canada Inc.",
    language: "en_ca",
    country: "CAN",
    callingCode: "+1",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_END_OF_MONTH_PLUS_45_DAYS,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "End of month + 45 days", language: "en_ca" },
          { value: "End of month + 45 days", language: "fr_ca" },
        ],
        description: [
          {
            value: "",
            language: "en_ca",
          },
        ],
      },
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [
          { value: "Cash Before Delivery", language: "en_ca" },
          { value: "Cash Before Delivery", language: "fr_ca" },
        ],
        description: [
          {
            value: "",
            language: "en_ca",
          },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: true,
    budget: true,
    budgetNotVisibleForCustomers: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: false,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: false,
    BIC: false,
    signerDropDown: false,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: ["FR_CA"],
    bankName: false,
    deliveryDate: false,
    comment: true,
    logisticContact: false,
    noSingleReturn: false,
    hideAccountsDepartmentPhoneNumber: false,
    hideContactPerson: true,
    hideStoreEmail: true,
    hideDeliveryOverride: true,
    regions: true,
    directPlatformEmail: true,
    openSince: true,
    orderConfirmationEmailOnBillTo: true,
    freightChargeCheckbox: true,
    noReturnDamageCheckbox: true,
    deliveryTermDownloadLink: true,
    paymentTermAccept: true,
    copyBillToAddressButton: true,
    regionToDeliveryTermVersion: [
      { regionId: 9, version: "1" }, // Ontario
      { regionId: 11, version: "1" }, // Quebec
    ],
  },
  {
    code: 214,
    name: "BESTSELLER Italy S.p.A.",
    language: "it",
    country: "ITA",
    callingCode: "+39",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_10_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "sdd 10 gg sconto 2%", language: "it" }],
        description: [
          {
            value: "",
            language: "it",
          },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET_PLUS_END_OF_MONTH,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "sdd 30 gg fm", language: "it" }],
        description: [
          {
            value: "",
            language: "it",
          },
        ],
      },
      {
        code: PAYMENT_TERM_60_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "sdd 60 gg df", language: "it" }],
        description: [
          {
            value: "",
            language: "it",
          },
        ],
      },
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Cash before delivery", language: "it" }],
        description: [
          {
            value: "",
            language: "it",
          },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    budgetNotVisibleForCustomers: false,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: false,
    BIC: false,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: false,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    hideAccountsDepartmentPhoneNumber: false,
    hideContactPerson: true,
    hideStoreEmail: false,
    hideDeliveryOverride: false,
    regions: false,
    directPlatformEmail: false,
    openSince: false,
    orderConfirmationEmailOnBillTo: false,
    freightChargeCheckbox: false,
    noReturnDamageCheckbox: false,
    deliveryTermDownloadLink: false,
    paymentTermAccept: false,
    showDirectDebitEvenIfPaymentTermNotVisible: true,
    directDebitIBANOptional: true,
    directDebitLegalNameOptional: true,
    directDebitStoreNameOptional: true,
    createAdminUser: true,
  },
  {
    code: 516,
    name: "VILA Italy s.r.l.",
    language: "it",
    country: "ITA",
    callingCode: "+39",
    smsCodeCellPhoneNumber: true,
    paymentTerms: [
      {
        code: PAYMENT_TERM_10_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "sdd 10 gg sconto 2%", language: "it" }],
        description: [
          {
            value: "",
            language: "it",
          },
        ],
      },
      {
        code: PAYMENT_TERM_30_DAYS_NET_PLUS_END_OF_MONTH,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "sdd 30 gg fm", language: "it" }],
        description: [
          {
            value: "",
            language: "it",
          },
        ],
      },
      {
        code: PAYMENT_TERM_60_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: true,
        name: [{ value: "sdd 60 gg df", language: "it" }],
        description: [
          {
            value: "",
            language: "it",
          },
        ],
      },
      {
        code: PAYMENT_TERM_CASH_BEFORE_DELIVERY,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "Cash before delivery", language: "it" }],
        description: [
          {
            value: "",
            language: "it",
          },
        ],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: false,
    budget: true,
    budgetNotVisibleForCustomers: false,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: false,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: false,
    regNumberMinDigits: 5,
    regNumberMaxDigits: 5,
    accountNumber: false,
    BIC: false,
    signerDropDown: false,
    billing: true,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: true,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    alernativeLanguages: [],
    bankName: false,
    deliveryDate: false,
    comment: false,
    logisticContact: false,
    noSingleReturn: false,
    hideAccountsDepartmentPhoneNumber: false,
    hideContactPerson: true,
    hideStoreEmail: false,
    hideDeliveryOverride: false,
    regions: false,
    directPlatformEmail: false,
    openSince: false,
    orderConfirmationEmailOnBillTo: false,
    freightChargeCheckbox: false,
    noReturnDamageCheckbox: false,
    deliveryTermDownloadLink: false,
    paymentTermAccept: false,
    showDirectDebitEvenIfPaymentTermNotVisible: true,
    directDebitIBANOptional: true,
    directDebitLegalNameOptional: true,
    directDebitStoreNameOptional: true,
    createAdminUser: true,
  },
  {
    code: 589,
    name: "BESTSELLER MENA GmbH",
    language: "en_me",
    country: "",
    callingCode: "",
    smsCodeCellPhoneNumber: false,
    paymentTerms: [
      {
        code: PAYMENT_TERM_45_DAYS_NET,
        onlyVisibleForCountry: undefined,
        notVisibleForCountry: undefined,
        directDebit: false,
        name: [{ value: "45 days net", language: "en_me" }],
        description: [{ value: "", language: "en_me" }],
      },
    ],
    dataProtectionCheckmark: true,
    paymentTermsNotVisibleForCustomers: true,
    guaranteeNotVisibleForCustomers: true,
    budget: true,
    guarantee: true,
    guaranteeAmountReadOnlyForCustomer: true,
    guaranteeType: true,
    kvkNumber: false,
    companyRegistrationNumber: true,
    companyRegistrationNumberOptionalCountry: undefined,
    regNumber: true,
    regNumberMinDigits: 1,
    regNumberMaxDigits: 30,
    accountNumber: true,
    BIC: false,
    signerDropDown: false,
    billing: false,
    sirenNumber: false,
    contactPersonFunction: false,
    contractTypes: null,
    legalForms: null,
    vatNumberRequired: false,
    accountsDepartmentPhoneNumberRequired: true,
    storePhoneNumberRequired: true,
    storeCellPhoneNumberHidden: false, // compile please?
    alernativeLanguages: [],
    bankName: true,
    deliveryDate: false,
    comment: true,
    logisticContact: false,
    noSingleReturn: false,
    copyBillToAddressButton: true,
    noWholesaleAgreement: true,
    showDirectDebitEvenIfPaymentTermNotVisible: false,
  },
];
